import {Component, OnInit, Renderer2} from '@angular/core';
import {AuthOtpService} from '../shared/services/auth.otp.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  public assets_path = environment.assets_path;

  constructor(
    private renderer: Renderer2,
    private authOtpService: AuthOtpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'layout-top-nav');
  }

  public isLogged(): boolean{
    return this.authOtpService.isOtpLogged();
  }

  public logout(): void{
    this.authOtpService.logout();
    this.router.navigate(['/otp/login']);
  }

}
