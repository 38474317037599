<div class="wrapper">
  <nav *ngIf="isLogged()" class="main-header navbar navbar-expand-md navbar-light navbar-white">
    <div class="container">
      <a class="navbar-brand" href="../../index3.html">
        <img [src]="'assets/'+assets_path+'/img/logo.png'" alt="AdminLTE Logo" width="30%">
      </a>
      <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ms-auto">
        <a (click)="logout()" class="nav-link" data-slide="true" data-widget="control-sidebar" href="#" role="button">Logout</a>
      </ul>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
