import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequest } from '../models/request/login.request';
import { BaseResponse } from '../models/respose/BaseResponse';
import { User } from '../models/User';
import { HttpService } from './http.service';
import {OtpLoginRequest} from '../../otp/routes/models/OtpLoginRequest';
import {environment} from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthOtpService extends HttpService{

  protected getAuthHeader(): HttpHeaders {
    if (localStorage.getItem('token_otp')){
      return new HttpHeaders(
        {
          Authorization: 'Bearer ' + localStorage.getItem('token_otp')
        }
      );
    }
    return new HttpHeaders();
  }

  isOtpLogged(): boolean{
    return !!localStorage.getItem('token_otp');
  }
  login(request: OtpLoginRequest): Observable<BaseResponse<User>>{
    return this.post('login', request);
  }
  logout(): void{
    this.get('logout').subscribe(
      result => {
        localStorage.removeItem('token_otp');
      }
    );
  }
  protected get environmentPath(): string{
    return environment.api_otp;
  }
  getPractice(): any{
    return JSON.parse(localStorage.getItem('practice') as string);
  }

}
